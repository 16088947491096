import { Modal } from 'components/Modal/Modal';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { ReactComponent as WelcomeProductTour } from 'assets/images/welcome_producttour.svg';
import ConfettiOverlay from './ConfettiOverlay';

const WelcomeModal = ({
  isOpen,
  close,
  onGo,
}: {
  isOpen: boolean;
  close: () => void;
  onGo: () => void;
}) => {
  return (
    <Modal isVisible={isOpen} close={close} className="!z-[1050]">
      <ConfettiOverlay />
      <div className="flex max-w-[723px] flex-col gap-2 rounded-lg bg-background-base-surface-2">
        <h1 className="rounded-t-lg bg-background-base-surface-3 px-8 py-6  text-[28px] font-bold leading-9 text-content-base-default">
          Welcome to Granite360!
        </h1>
        <div className="h-[358px] max-h-[358px] px-8 py-6 ">
          <div className="w-[659px] sm:flex">
            <div className="flex flex-col justify-center gap-4">
              <div className="text-[28px]  font-bold text-content-base-default">
                Let’s start with a quick product tour
              </div>
              <div className="text-base font-bold text-content-base-subdued">
                Get started in just a few clicks.
              </div>
            </div>
            <div className="flex flex-shrink-0 items-center justify-center p-4 pr-0">
              <WelcomeProductTour width="198px" height="198px" />
            </div>
          </div>
          <div className="mt-6 flex gap-5">
            <GraniteButton
              size="large"
              variant="secondary"
              onClick={() => {
                close();
              }}
            >
              Remind me later
            </GraniteButton>
            <GraniteButton
              size="large"
              variant="primary"
              type="submit"
              onClick={() => {
                onGo();
              }}
            >
              Let&apos;s go!
            </GraniteButton>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default WelcomeModal;
