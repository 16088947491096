import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { DeepPartial } from 'react-hook-form';
import { useQuery, useQueryClient } from 'react-query';
import clsx from 'clsx';

import { OpenQuoteLayout } from '../BaseComponents/OpenQuoteLayout';
import LocationSection from '../BaseComponents/LocationSection';
import { PricingTier, PricingTierResponse } from 'api/accessexpress/schema';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { useModal } from 'hooks/useModal';
import { RequestQuoteModal } from '../BaseComponents/RequestQuoteModal';
import { useOpenQuoteContext } from '../Wizard/OpenQuoteWizardReducer';
import { getQuoteBucketsRequest, selectBucketsRequest } from './utils';
import { SiteAddress } from 'api/addresssearch/schema';
import DiscardProgressModal from '../DiscardProgressModal/DiscardProgressModal';
// import { AlertCircle } from 'react-ionicons';
import BasicPagination, {
  useBasicPagination,
} from 'components/BasicPagination';
import ResultsLoading from '../BaseComponents/ResultsLoading';
import { selectMoreOptions } from 'api/accessexpress/api';

export interface SelectedTierInfo {
  tier: DeepPartial<PricingTier> | null;
  sendMePricing: boolean;
}

interface OldDataType {
  quote_name: string;
  qe_quote_request_id: number;
  qe_quote_created_at: string;
  requester: string;
  status: string;
  locations: Location[];
  pricingTiers: PricingTierResponse[];
}

const REFETCH_DELAYS = [1, 2, 5, 5, 10, 20, 30, 60, 120, 120];

const LOCATIONS_PER_PAGE = 5;

export const SelectServiceBundle = () => {
  const location = useLocation();
  const { quoteId } = useParams();
  const { wizardStore } = useOpenQuoteContext();
  const navigate = useNavigate();
  const { open, ...discardModalProps } = useModal();
  // const [errors, setErrors] = useState<{ [locationId: string]: boolean }>({});
  const [moreOptionsSelected, setMoreOptionsSelected] = useState<{
    [locationId: string]: boolean;
  }>({});

  const queryClient = useQueryClient();
  const isSimpleFlow = location.state?.isSimpleFlow;

  const retryIndex = useRef(0);
  const { data, isLoading, refetch } = useQuery(
    ['access-express-quote-buckets', quoteId],
    () => getQuoteBucketsRequest(quoteId ?? ''),
    {
      enabled: !!quoteId,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      onSuccess: (data) => {
        if (
          data?.status !== 'Service results ready' &&
          retryIndex.current < REFETCH_DELAYS.length
        ) {
          const delay = REFETCH_DELAYS[retryIndex.current] * 1000;
          setTimeout(() => {
            refetch();
          }, delay);
          retryIndex.current++;
        }
      },
    },
  );

  const locationPricingTiers = data?.pricingTiers;
  const hasTwoProductTypes = data?.hasTwoProductTypes;

  useEffect(() => {
    wizardStore.setStep(3);
  }, [wizardStore]);

  const [selectedTiers, setSelectedTiers] = useState<{
    [key: string]: SelectedTierInfo;
  }>({});

  const { openWithProps, ...modalProps } = useModal<{
    [key: string]: SelectedTierInfo;
  }>();

  useEffect(() => {
    if (locationPricingTiers) {
      const initialSelectedTiers = locationPricingTiers.reduce(
        (acc, loc) => {
          const advancedTier = loc.pricing_tiers.find((tier) => tier.selected);
          acc[loc.id] = {
            tier: advancedTier ? { ...advancedTier } : null,
            sendMePricing: loc.sendMePricing,
          };
          return acc;
        },
        {} as { [key: string]: SelectedTierInfo },
      );
      setSelectedTiers(initialSelectedTiers);

      const initialSelectedMoreOptions = locationPricingTiers.reduce(
        (acc, loc) => ({
          ...acc,
          [loc.id]: loc.sendMePricing,
        }),
        {} as { [key: string]: boolean },
      );
      setMoreOptionsSelected(initialSelectedMoreOptions);
    }
  }, [locationPricingTiers]);

  const handleChooseService = useCallback(() => {
    navigate('/access-express/open-quote/choose-services', {
      state: {
        quoteId,
        addressValue: {
          address_line_1:
            locationPricingTiers?.[0].location.address_line_1 ?? '',
          address_line_2:
            locationPricingTiers?.[0].location.address_line_2 ?? '',
          city: locationPricingTiers?.[0].location.city ?? '',
          state: locationPricingTiers?.[0].location.state ?? '',
          zip: locationPricingTiers?.[0].location.zip ?? '',
        } as SiteAddress,
      },
    });
  }, [locationPricingTiers, navigate, quoteId]);

  const productTypesCount = hasTwoProductTypes ? 2 : 1;

  const { from, to, ...paginationProps } = useBasicPagination(
    locationPricingTiers?.length ?? 0,
    LOCATIONS_PER_PAGE * productTypesCount,
  );

  const handleSelectTier = (
    locationId: string,
    tier: DeepPartial<PricingTier>,
  ) => {
    setSelectedTiers((prev) => {
      const isSelected = prev[locationId]?.tier?.name === tier.name;

      selectBucketsRequest({
        quote_id: tier.quote_id ?? '',
        product_id: tier.product_id ?? '',
        bucket_id: tier.bucket_id ?? '',
        selected: !isSelected,
      });

      if (isSelected) {
        return {
          ...prev,
          [locationId]: {
            ...prev[locationId],
            tier: null, // Deselect the tier
          },
        };
      }

      // setErrors((currentErrors) => ({
      //   ...currentErrors,
      //   [locationId]: false,
      // }));

      return {
        ...prev,
        [locationId]: {
          tier,
          sendMePricing: prev[locationId]?.sendMePricing || false,
        },
      };
    });
  };

  const onRequestQuoteClick = () => {
    // let hasErrors = false;
    // const updatedErrors = { ...errors };

    // Object.keys(selectedTiers).forEach((locationId) => {
    //   if (
    //     !selectedTiers[locationId]?.tier &&
    //     !moreOptionsSelected[locationId]
    //   ) {
    //     updatedErrors[locationId] = true;
    //     hasErrors = true;
    //   } else {
    //     updatedErrors[locationId] = false;
    //   }
    // });
    // setErrors(updatedErrors);

    // if (hasErrors) {
    //   const firstErrorLocationId = Object.keys(updatedErrors).find(
    //     (locationId) => updatedErrors[locationId],
    //   );
    //   setTimeout(() => {
    //     if (firstErrorLocationId) {
    //       const element = document.getElementById(
    //         `location-${firstErrorLocationId}`,
    //       );
    //       if (element) {
    //         element.scrollIntoView({
    //           behavior: 'smooth',
    //         });
    //       }
    //     }
    //   }, 0);
    //   return;
    // }

    openWithProps(selectedTiers);
  };

  const handleRemoveLocation = (locationId: string) => {
    setSelectedTiers((prevSelectedTiers) => {
      const { [locationId]: _, ...remainingTiers } = prevSelectedTiers;
      return remainingTiers;
    });

    // setErrors((prevErrors) => {
    //   const { [locationId]: _, ...remainingErrors } = prevErrors;
    //   return remainingErrors;
    // });

    setMoreOptionsSelected((prevMoreOptions) => {
      const { [locationId]: _, ...remainingMoreOptions } = prevMoreOptions;
      return remainingMoreOptions;
    });

    queryClient.setQueryData<OldDataType | undefined>(
      ['access-express-quote-buckets', quoteId],
      (oldData) => {
        if (!oldData) return oldData;
        const updatedPricingTiers = oldData.pricingTiers.filter(
          (tier) => !tier.id.startsWith(locationId),
        );

        return {
          ...oldData,
          pricingTiers: updatedPricingTiers,
        };
      },
    );
  };

  // const hasErrors = Object.values(errors).some((error) => error === true);

  const handleMoreOptionsSelect = (locationId: string) => {
    const newSelection = !moreOptionsSelected[locationId];

    const [quoteId, productId] = locationId.split('|');
    selectMoreOptions(quoteId, productId, newSelection);

    setMoreOptionsSelected((prev) => ({
      ...prev,
      [locationId]: newSelection,
    }));

    // if (newSelection) {
    //   setErrors((currentErrors) => ({
    //     ...currentErrors,
    //     [locationId]: false,
    //   }));
    // }
  };

  return (
    <OpenQuoteLayout
      className={clsx(hasTwoProductTypes ? '!grid-cols-none' : '')}
    >
      <div className="flex flex-col gap-8 rounded bg-background-base-surface-2 p-6 shadow">
        <div className="flex w-full flex-col items-start justify-start">
          <h2 className="mb-2 text-2xl font-bold text-content-base-default">
            Choose from the below service packages for each location
          </h2>
        </div>
        {isLoading && (
          <div
            className={clsx('gap-4 rounded bg-background-base-surface-1 p-4')}
          >
            <ResultsLoading />
          </div>
        )}
        <div
          className={clsx(
            hasTwoProductTypes
              ? '2xl:-mx-4 2xl:-my-4 2xl:flex 2xl:flex-wrap'
              : '',
          )}
        >
          {locationPricingTiers?.slice(from, to).map((location, index) => {
            return (
              <div
                key={location.id}
                className={clsx(
                  'py-4',
                  hasTwoProductTypes && '2xl:w-1/2 2xl:px-4',
                )}
              >
                <LocationSection
                  location={location}
                  onSelectedTier={(tier) =>
                    handleSelectTier(location.id, tier!)
                  }
                  selectedTier={selectedTiers[location.id]?.tier}
                  pricingAvailable={data?.status === 'Service results ready'}
                  locationPricingTiersLength={locationPricingTiers.length}
                  // errors={errors}
                  onRemoveLocation={() => handleRemoveLocation(location.id)}
                  onMoreOptionsSelect={() =>
                    handleMoreOptionsSelect(location.id)
                  }
                  isMoreOptionsSelected={moreOptionsSelected[location.id]}
                  defaultCollapsed={
                    paginationProps.currentPage > 1 ||
                    index >= productTypesCount
                  }
                  isLoading={isLoading}
                  shouldAnimate={isSimpleFlow}
                />
              </div>
            );
          })}
        </div>
        <div className="flex flex-row items-center">
          <GraniteButton
            variant="secondary"
            onClick={handleChooseService}
            className="whitespace-nowrap"
          >
            Manage locations & services
          </GraniteButton>
          <BasicPagination {...paginationProps} />
        </div>
      </div>
      <div className="sticky top-8 flex flex-col gap-6 rounded bg-background-base-surface-2 p-6 shadow">
        {/* {hasErrors && (
          <div
            className={
              'flex w-full items-center gap-2 rounded border border-status-error-default bg-background-base-surface-1 p-4'
            }
          >
            <AlertCircle color="#FF315E" width="24px" height="24px" />
            <span className="text-base font-bold text-content-base-default">
              You must select a service package for each requested location{' '}
            </span>
          </div>
        )} */}
        <div className="flex gap-4">
          <GraniteButton
            variant="secondary"
            size="large"
            className={clsx(hasTwoProductTypes ? '' : 'w-full')}
            onClick={open}
          >
            Cancel
          </GraniteButton>
          <GraniteButton
            size="large"
            className={clsx(hasTwoProductTypes ? '' : 'w-full')}
            onClick={onRequestQuoteClick}
            disabled={
              !(
                Object.values(selectedTiers).some((tier) => tier.tier) ||
                Object.values(moreOptionsSelected).some(
                  (isSelected) => isSelected,
                )
              ) // || hasErrors
            }
          >
            Request quote
          </GraniteButton>
        </div>
      </div>
      <RequestQuoteModal
        {...modalProps}
        selectedTiers={selectedTiers}
        quoteName={data?.quote_name ?? ''}
        quoteId={quoteId ?? ''}
      />
      <DiscardProgressModal {...discardModalProps} />
    </OpenQuoteLayout>
  );
};
