import { FC } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { HeaderNavGranite } from 'components';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { Auth0RedirectionLoader } from '../../components/Auth0RedirectionLoader/Auth0RedirectionLoader';
import clsx from 'clsx';
import { useElementPresence } from 'shared/hooks/useElementPresence';
import ProductTour from 'components/ProductTour/ProductTour';
import { InAppNotifications } from 'components/Notifications/InAppNotifications';

export const DefaultLayout: FC = () => {
  const { isLoading } = useAuth0();
  const location = useLocation();
  const isWidgetOpen = useElementPresence(
    '.announcekit-widget-iframe-backdrop',
  );

  if (isLoading) return <Auth0RedirectionLoader />;

  return (
    <div
      className={clsx(
        `relative flex min-h-screen flex-col justify-start bg-background-base-surface-1`,
        location.pathname === '/' && 'home-bg bg-cover',
      )}
    >
      <HeaderNavGranite menuOptions={[]} />
      <ProductTour />
      <div className="mx-auto flex h-full w-full grow justify-center">
        <Outlet />
      </div>
      <div
        style={{ zIndex: isWidgetOpen ? 9999999999999 : 0 }}
        className={`${
          isWidgetOpen ? 'opacity-1' : 'opacity-0'
        } fixed bottom-[10px] right-[10px] h-[37px] w-[400px] bg-background-base-surface-2 transition-opacity transition-transform duration-200 ease-in ${
          isWidgetOpen ? 'translate-x-0' : 'translate-x-14'
        }`}
      />
      <InAppNotifications />
    </div>
  );
};

export const ProtectedDefaultLayout = withAuthenticationRequired(
  DefaultLayout,
  {
    onRedirecting: Auth0RedirectionLoader,
  },
);
