import { createPaginatedResponseSchema } from 'api/common-schemas';
import { z } from 'zod';

const CompanySchema = z.object({
  id: z.string(),
  display_name: z.string(),
  name: z.string(),
  metadata: z.object({
    company_id: z.string(),
    macnum: z.string(),
  }),
});

export type Company = z.infer<typeof CompanySchema>;

export const GetCompaniesResponseSchema =
  createPaginatedResponseSchema(CompanySchema);

export type GetCompaniesResponse = z.infer<typeof GetCompaniesResponseSchema>;

export const GetCompaniesParamsSchema = z
  .object({
    search: z.string(),
    page: z.number(),
    page_size: z.number(),
  })
  .partial();

export type GetCompaniesParams = z.infer<typeof GetCompaniesParamsSchema>;
