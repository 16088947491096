import { OptionLabelValueSchema } from 'screens/LetUsHelp/utils/schemas';
import { VALID_PHONE_ERROR_LABEL } from 'shared/constants/error-labels';
import {
  NUMBERS_ONLY_REGEXP,
  VALID_PHONE_NUMBER,
} from 'shared/constants/validation-regex-constants';
import { z } from 'zod';

export const InvoiceStyleEnum = z.enum([
  'Summary Bill',
  'IW Only',
  'Child-No Bill',
]);

export const AccountInformationFormSchema = z.object({
  parent_account: OptionLabelValueSchema.merge(
    z.object({
      subvalue: z.string(),
    }),
  ),
  billing_type: OptionLabelValueSchema,
  invoice_delivery_method: OptionLabelValueSchema,
  invoice_style: z.object({
    label: InvoiceStyleEnum,
    value: InvoiceStyleEnum,
  }),
  store_id: z.string().optional(),
  general_ledger: z.string().optional(),
  local_contact_name: z.string().nonempty('Required'),
  local_contact_email: z.string().nonempty('Required').email(),
  local_contact_phone_number: z
    .string()
    .nonempty('Required')
    .length(10, VALID_PHONE_ERROR_LABEL)
    .regex(VALID_PHONE_NUMBER, VALID_PHONE_ERROR_LABEL),
});

export type AccountInformationForm = z.infer<
  typeof AccountInformationFormSchema
>;

export const ServiceDetailsFormSchema = z.object({
  business_name: z
    .string()
    .nonempty('Required')
    .max(39, 'Only 39 characters allowed'),
  address_line_1: z.string().nonempty('Required'),
  address_line_2: z.string().optional(),
  city: z
    .string()
    .nonempty('Required')
    .min(4, 'City must be at least 4 characters')
    .max(16, 'City must be no longer than 16 characters'),
  state: z.object({
    label: z.string(),
    value: z.string().max(2, 'Only 2 characters allowed'),
  }),
  zip_code: z
    .string()
    .nonempty('Required')
    .min(5, 'Zip code must be 5 characters')
    .max(5, 'Only 5 characters allowed')
    .regex(NUMBERS_ONLY_REGEXP, {
      message: 'Only numbers allowed',
    }),
  country: z.object({
    label: z.literal('USA'),
    value: z.literal('USA'),
  }),
});

export const BillingAddressSchema = z.object({
  billing_business_name: z
    .string()
    .nonempty('Required')
    .max(39, 'Only 39 characters allowed'),
  billing_contact_name: z.string().nonempty('Required'),
  billing_contact_phone_number: z
    .string()
    .nonempty('Required')
    .length(10, VALID_PHONE_ERROR_LABEL)
    .regex(VALID_PHONE_NUMBER, VALID_PHONE_ERROR_LABEL),
  billing_address_line_1: z.string().nonempty('Required'),
  billing_address_line_2: z.string().optional(),
  billing_city: z
    .string()
    .nonempty('Required')
    .min(4, 'City must be at least 4 characters')
    .max(16, 'City must be no longer than 16 characters'),
  billing_state: OptionLabelValueSchema,
  billing_zip_code: z
    .string()
    .nonempty('Required')
    .min(5, 'Zip code must be 5 characters')
    .max(5, 'Only 5 characters allowed')
    .regex(NUMBERS_ONLY_REGEXP, {
      message: 'Only numbers allowed',
    }),
  billing_country: OptionLabelValueSchema,
});

const FullServiceDetailsWithFalseSameAsAbove = ServiceDetailsFormSchema.extend({
  billing_address: z.literal(false),
  ...BillingAddressSchema.shape,
});

const FullServiceDetailsWithTrueSameAsAbove = ServiceDetailsFormSchema.extend({
  billing_address: z.literal(true),
  billing_business_name: z.string().optional(),
  billing_contact_name: z.string().optional(),
  billing_contact_phone_number: z.string().optional(),
  billing_address_line_1: z.string().optional(),
  billing_address_line_2: z.string().optional(),
  billing_city: z.string().optional(),
  billing_state: OptionLabelValueSchema.optional(),
  billing_zip_code: z.string().optional(),
  billing_country: OptionLabelValueSchema.optional(),
});

export const FullServiceDetailsFormSchema = z.discriminatedUnion(
  'billing_address',
  [
    FullServiceDetailsWithFalseSameAsAbove,
    FullServiceDetailsWithTrueSameAsAbove,
  ],
);

export type FullServiceDetailsFormType = z.infer<
  typeof FullServiceDetailsFormSchema
>;
export type ServiceDetailsForm = z.infer<typeof ServiceDetailsFormSchema>;

export type ChildAccountRequest = {
  parent_account: string;
  parent_name: string;
  ship_country: string;
  billing_type: string;
  invoice_delivery_method: string;
  invoice_style: string;
  state: string;
  country: string;
  business_name: string;
  address_line_1: string;
  address_line_2?: string;
  city: string;
  zip_code: string;
  local_contact_name: string;
  local_contact_phone_number: string;
  store_id?: string;
  general_ledger?: string;
  local_contact_email?: string;
  billing_business_name: string;
  billing_contact_name: string;
  billing_contact_phone_number: string;
  billing_address_line_1: string;
  billing_address_line_2?: string;
  billing_city: string;
  billing_state: string;
  billing_zip_code: string;
  billing_country: string;
};
