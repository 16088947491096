import React, { useEffect } from 'react';
import format from 'date-fns/format';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import PageTitle from 'components/PageTitle';
import { zodResolver } from '@hookform/resolvers/zod';
import { GraniteInput } from '../../components/V2/Input/GraniteInput';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { BadgeRole } from 'components/V2/BadgeRole/BadgeRole';
import DeleteAccount from './DeleteAccount';
import { clsx } from 'clsx';
import {
  EditUserForm,
  EditUserFormSchema,
  responseToUserEditForm,
  userEditFormToEditRequest,
} from './form-schema';
import { useMutation, useQuery } from 'react-query';
import { getUserByEmail, updateUser } from 'api/users/api';
import showToast from 'components/Toast/Toast';
import { WithLoader } from '../../components/WithLoader/WithLoader';
import { UserRolePermissionFeatureSwitches } from '../../components/UserPermissionFeatureSwitches/UserRolePermissionFeatureSwitches';
import Divider from '../../components/Divider';
import { useAuth0User } from '../../hooks/useAuth0User';
import { GraniteSelect } from '../../components/Select/Select';
import {
  UpdateUserProfileRequest,
  UserProfileResponse,
} from '../../api/users/schemas/UserProfile';
import PhoneNumberInput from 'components/V2/Input/PhoneNumber';

export const EditUser = () => {
  const { id: email } = useParams<{ id: string }>();
  const { companyName } = useAuth0User();
  const navigate = useNavigate();

  const { data, isLoading } = useQuery(
    ['edit-user', email],
    () => getUserByEmail({ email: email }),
    {
      refetchOnWindowFocus: false,
      enabled: !!email,
    },
  );

  const {
    handleSubmit,
    control,
    reset,
    register,
    formState: { errors },
  } = useForm<EditUserForm>({
    resolver: zodResolver(EditUserFormSchema),
    defaultValues: {},
  });

  useEffect(() => {
    if (data) {
      reset(responseToUserEditForm(data));
    }
  }, [data, reset]);

  const breadcrumbs = [
    {
      icon: 'home',
      label: 'User management',
      onClick: () => navigate('/users/'),
    },
  ];

  const updateUserRoleMutation = useMutation(
    (payload: UpdateUserProfileRequest) => updateUser(email, payload),
    {
      onSuccess: (response: UserProfileResponse) => {
        showToast.confirmation({
          message: 'User role has been successfully updated!',
        });
        reset(responseToUserEditForm(response));
        navigate(`/users/${email}`);
      },
      onError(error) {
        console.log(error, 'error');
      },
    },
  );

  if (!email) {
    navigate('/users');
    return;
  }

  const onSubmit = (form: EditUserForm) => {
    if (!data) return;
    updateUserRoleMutation.mutate(userEditFormToEditRequest(form, data));
  };

  const onError = (err: unknown) => {
    console.log({ err });
  };

  const userStatusClassName = data?.deactivated
    ? 'bg-user-status-deactivated'
    : 'bg-user-status-active';

  const dateAdded = data?.invited_at || data?.created_at;

  return (
    <div className="w-full p-8">
      <WithLoader isLoading={isLoading}>
        <div className="mx-auto flex w-full max-w-[1376px] flex-col items-start justify-start">
          <PageTitle
            title={data?.name ?? ''}
            breadcrumbs={breadcrumbs}
            className="mb-12 w-full"
          >
            <div className="ml-6 flex grow items-end">
              <BadgeRole
                role={data?.role ?? 'Viewer'}
                classNames="text-base py-1.5 h-[36px] px-3 ml-0"
              />
              <Link
                to={`/users/${email}`}
                className="button large secondary ml-auto mr-4"
              >
                Cancel
              </Link>
              <GraniteButton
                variant="primary"
                size="large"
                type="submit"
                form="invite-member-form"
                disabled={updateUserRoleMutation.isLoading}
                className="mr-0"
              >
                Save changes
              </GraniteButton>
            </div>
          </PageTitle>

          <div className="flex w-full flex-col items-start justify-start gap-6">
            <div className="flex w-full items-center justify-between rounded bg-background-base-surface-2 px-6 py-4">
              <div className="flex w-full items-center justify-start gap-3">
                <span
                  className={clsx('h-4 w-4 rounded-full', userStatusClassName)}
                ></span>
                <h2 className="text-xl font-bold text-content-base-default">
                  {data?.deactivated ? 'Deactivated' : 'Active'}
                </h2>
              </div>
              <div className="flex w-full flex-col items-end justify-start">
                <p className="text-base font-bold text-content-base-subdued">
                  Date Added
                </p>
                <h2 className="text-base font-bold text-content-base-default">
                  {dateAdded
                    ? format(new Date(dateAdded), 'MM/dd/yyyy')
                    : ' -- '}
                </h2>
              </div>
            </div>
            <form
              onSubmit={handleSubmit(onSubmit, onError)}
              className="self-stretch"
              id="invite-member-form"
            >
              <div className="flex rounded">
                <div className="min-w-[444px] rounded-l bg-background-base-surface-3 px-8 py-6">
                  <h2 className="mb-4 text-2xl font-bold text-content-base-default">
                    User details
                  </h2>
                  <div className="flex flex-col gap-6">
                    <GraniteInput
                      label="Name"
                      placeholder="Name"
                      {...register('name')}
                      error={errors.name?.message}
                    />
                    <GraniteInput
                      label="Email"
                      placeholder="Email"
                      value={email}
                      disabled
                    />
                    <PhoneNumberInput
                      name="phone_number"
                      label="Phone"
                      placeholder="(555)-555-555"
                      control={control}
                      error={errors.phone_number?.message}
                    />
                    <GraniteSelect
                      label="Company"
                      value={{ label: companyName, value: companyName }}
                      options={[{ label: companyName, value: companyName }]}
                      isDisabled
                    />
                  </div>
                </div>
                <div className="col-span-8 flex h-full w-full flex-col gap-16 rounded-r bg-background-base-surface-2 px-8 py-6">
                  <UserRolePermissionFeatureSwitches control={control} />
                  <Divider />
                  <DeleteAccount
                    userId={data?.user_id ?? ''}
                    onSuccessHandler={() => {
                      showToast.confirmation({
                        message: 'User has been successfully deleted!',
                      });
                      navigate('/users');
                    }}
                  />
                  <Divider />
                  <div className="flex gap-4">
                    <Link
                      to={`/users/${email}`}
                      className="button large secondary"
                    >
                      Cancel
                    </Link>
                    <GraniteButton
                      variant="primary"
                      size="large"
                      type="submit"
                      form="invite-member-form"
                      disabled={updateUserRoleMutation.isLoading}
                    >
                      Save changes
                    </GraniteButton>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </WithLoader>
    </div>
  );
};
