import { TokenUserRoles } from 'api/users/schemas/Users';
import { ReactNode } from 'react';
import { Placement } from 'react-joyride';
import { isStepAccessible } from './utils';

export interface StepDetail {
  step: number;
  pathname: string;
  target: string | HTMLElement;
  className?: string;
  content?: string | ReactNode;
  spotlightPadding?: number;
  spotLightRadius?: number;
  disableScrolling?: boolean;
  title?: string | ReactNode;
  placement?: Placement;
  userDropDownOpen: boolean;
  hasAccess?: (roles?: TokenUserRoles[]) => boolean;
}

export enum TourSteps {
  WELCOME_MODAL_STEP = 0,
  LAST_STEP = 24,
  REVISIT_LATER_STEP = 25,
}

export const getStepDetails = (): StepDetail[] => [
  {
    step: TourSteps.WELCOME_MODAL_STEP,
    target: 'body',
    pathname: '/',
    placement: 'center' as Placement,
    className: 'custom-modal-step',
    userDropDownOpen: false,
  },
  {
    step: 1,
    target: '.product-tour-dashboard',
    content:
      'This is your home dashboard. You can quickly return to your dashboard by clicking the Granite360 logo in the top left corner of the navigation bar.',
    placement: 'bottom-end',
    pathname: '/',
    className: 'mt-26',
    hasAccess: (roles) => isStepAccessible(roles, 'dashboard'),
    userDropDownOpen: false,
  },
  {
    step: 2,
    target: '.product-tour-tech-express',
    content:
      'Let’s navigate to TechExpress, where you can schedule and manage all inside wiring dispatch requests to locations serviced by Granite.',
    placement: 'bottom-end',
    pathname: '/',
    className: 'mt-26',
    hasAccess: isStepAccessible,
    userDropDownOpen: false,
  },
  {
    step: 3,
    target: '.product-tour-tech-express',
    content:
      'This is the TechExpress dashboard. Here, you can view all active and completed dispatch requests, as well as create new dispatch requests.',
    placement: 'bottom-end',
    pathname: '/tech-express',
    className: 'mt-26',
    hasAccess: isStepAccessible,
    userDropDownOpen: false,
  },
  {
    step: 4,
    target: '.tech-express-button',
    content:
      'To create a new dispatch request, click the “Open ticket” button.',
    placement: 'bottom-end',
    pathname: '/tech-express',
    hasAccess: (roles) => isStepAccessible(roles, 'open_ticket'),
    userDropDownOpen: false,
  },
  {
    step: 5,
    title: 'Open ticket',
    target: '.tech-product-tour-step-1',
    content:
      'In the “Get started” section, you’ll provide the reason for dispatch & the general location details, including the address & requested date and time for technician dispatch.',
    placement: 'bottom-end',
    pathname: '/tech-express/let-us-help',
    hasAccess: (roles) => isStepAccessible(roles, 'open_ticket'),
    userDropDownOpen: false,
  },
  {
    step: 6,
    title: 'Open ticket',
    target: '.tech-product-tour-step-2',
    content:
      'Next, you’ll add more specific details regarding the dispatch request, such network or equipment descriptions, testing contact information, and any non-standard tool requirements.',
    placement: 'bottom-end',
    pathname: '/tech-express/let-us-help/details',
    hasAccess: (roles) => isStepAccessible(roles, 'open_ticket'),
    userDropDownOpen: false,
  },
  {
    step: 7,
    title: 'Open ticket',
    target: '.tech-product-tour-step-3',
    content:
      'In the “Finalize & review” section, you’ll review your ticket & submit your dispatch request',
    placement: 'bottom-end',
    pathname: '/tech-express/let-us-help/review',
    hasAccess: (roles) => isStepAccessible(roles, 'open_ticket'),
    userDropDownOpen: false,
  },
  {
    step: 8,
    target: '.terms-conditions',
    content:
      'Tickets can be edited or canceled prior to the technician’s dispatch. See here for terms & conditions.',
    placement: 'left-end',
    pathname: '/tech-express/let-us-help/review?open',
    userDropDownOpen: false,
    hasAccess: (roles) => isStepAccessible(roles, 'open_ticket'),
    disableScrolling: true,
  },
  {
    step: 9,
    target: '.edit-ticket-product-tour',
    content:
      'To edit or cancel a ticket, select a ticket and scroll to the bottom of the page.',
    placement: 'right-end',
    pathname: '/tech-express/1',
    hasAccess: (roles) => isStepAccessible(roles, 'open_ticket'),
    userDropDownOpen: false,
  },

  {
    step: 10,
    target: '.product-tour-noc-express',
    content:
      'Next, let’s take a look at NOCExpress - the feature where you can submit repair requests for your services.',
    placement: 'bottom-end',
    pathname: '/',
    hasAccess: isStepAccessible,
    userDropDownOpen: false,
  },

  // noc express steps
  {
    step: 11,
    target: '.product-tour-noc-index',
    title: 'Repair tickets',
    content:
      'Here, you can view and manage all of your company’s repair tickets.',
    pathname: '/noc-express?tab=tickets',
    placement: 'bottom',
    hasAccess: isStepAccessible,
    userDropDownOpen: false,
  },
  {
    step: 12,
    target: '.product-tour-noc-dashboard',
    content:
      'This is your NOCExpress dashboard. Here, you can view a variety of metrics related to your company’s active repair tickets.',
    pathname: '/noc-express?tab=dashboard',
    placement: 'bottom',
    hasAccess: isStepAccessible,
    userDropDownOpen: false,
  },
  {
    step: 13,
    target: '.product-tour-noc-open-button',
    title: 'Repair tickets',
    content:
      'To create a new repair ticket, click the “Open repair ticket” button.',
    pathname: '/noc-express',
    placement: 'bottom',
    hasAccess: (roles) => isStepAccessible(roles, 'noc_ticket'),
    userDropDownOpen: false,
  },
  {
    step: 14,
    target: '.product-tour-noc-open-find-location',
    title: 'Repair tickets',
    content:
      'When opening a new ticket, you’ll need to identify the location & service that needs attention.',
    pathname: '/noc-express/open-ticket',
    placement: 'bottom',
    hasAccess: (roles) => isStepAccessible(roles, 'noc_ticket'),
    userDropDownOpen: false,
  },
  {
    step: 15,
    target: '.product-tour-noc-open-add-details',
    title: 'Repair tickets',
    content:
      'Then add details describing the issue and testing details or attachments that are applicable for the location. Once ready, click the "Submit" button to submit your new ticket. A Granite representative will be automatically assigned to investigate.',
    pathname: '/noc-express/open-ticket/details',
    placement: 'bottom',
    hasAccess: (roles) => isStepAccessible(roles, 'noc_ticket'), // Restrict VIEWER
    userDropDownOpen: false,
  },
  {
    step: 16,
    target: '.product-tour-noc-view',
    content:
      'Once a ticket has been submitted, click into the ticket to view and manage it.',
    pathname: '/noc-express?tab=tickets',
    placement: 'bottom',
    hasAccess: isStepAccessible,
    userDropDownOpen: false,
  },
  {
    step: 17,
    target: '.product-tour-noc-activity',
    content:
      'Visit the “Activity” section of the ticket to communicate with the assigned Granite representative or check for recent ticket updates.',
    pathname: '/noc-express/1',
    placement: 'top',
    hasAccess: isStepAccessible,
    userDropDownOpen: false,
  },
  {
    step: 18,
    target: '.product-tour-noc-edit',
    content:
      'Edit ticket details or close tickets by clicking into the ticket and navigating to the bottom of the page. ',
    pathname: '/noc-express/1',
    placement: 'bottom-start',
    hasAccess: isStepAccessible,
    userDropDownOpen: false,
  },
  {
    step: 19,
    target: '.product-tour-noc-escalate',
    content:
      'You can escalate tickets when they require attention from a higher level of support. After a ticket has been open for at least two hours, click the "Escalate" button and choose the reason for escalation that best fits your situation.',
    pathname: '/noc-express/1',
    placement: 'bottom',
    hasAccess: (roles) => isStepAccessible(roles, 'noc_ticket'), // Restrict VIEWER
    userDropDownOpen: false,
  },
  {
    step: 20,
    target: '.product-tour-mobility',
    title: 'Looking for the Mobility portal?',
    content:
      'Navigate here to seamlessly access Granite’s Mobility Marketplace & Mobility Dashboard portals.',
    pathname: '/',
    placement: 'bottom',
    hasAccess: (roles) => isStepAccessible(roles, 'mobility'),
    userDropDownOpen: false,
  },
  // user management steps
  {
    step: 21,
    target: '.product-tour-user-management',
    content:
      'The User Management page is where Admin users can invite new members, manage invitations, and control permissions for existing members.',
    placement: 'left',
    pathname: '/',
    className: 'mt-26',
    hasAccess: (roles) => isStepAccessible(roles, 'user_management'),
    userDropDownOpen: true,
  },
  {
    step: 22,
    target: '.product-tour-user-management-invite-user',
    content:
      'Invite new users from your organization by clicking the Invite user button and completing the required info.',
    placement: 'bottom-end',
    pathname: '/users',
    className: 'mt-26',
    hasAccess: (roles) => isStepAccessible(roles, 'user_management'),
    userDropDownOpen: false,
  },
  {
    step: 23,
    target: '.product-tour-user-management-tab',
    content:
      'Revoke or resend invitations in the Pending invitations tab. Edit permissions or remove existing users by clicking into their account.',
    placement: 'bottom-end',
    pathname: '/users',
    className: 'mt-26',
    hasAccess: (roles) => isStepAccessible(roles, 'user_management'),
    userDropDownOpen: false,
  },
  // last step
  {
    step: TourSteps.LAST_STEP,
    target: '.product-tour-menu',
    title: 'Thanks for using Granite360!',
    content:
      'Access this product tour anytime by clicking in your account drop down and selecting “Product tour.”',
    placement: 'left',
    pathname: '/',
    className: 'mt-26',
    userDropDownOpen: true,
  },
  // if user closes the modal at any time during the tour
  {
    step: TourSteps.REVISIT_LATER_STEP,
    target: '.product-tour-menu',
    title: 'Want to revisit later?',
    content:
      'Access this product tour anytime by clicking in your account drop down and selecting “Product tour.””',
    placement: 'left',
    pathname: '/',
    className: 'mt-26',
    userDropDownOpen: true,
  },
];
