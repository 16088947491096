import { getCompanyAdmins } from 'api/companies/api';
import Loader from 'components/Loader';
import { Modal } from 'components/Modal/Modal';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { GraniteInput } from 'components/V2/Input/GraniteInput';
import { useAuth0User } from 'hooks/useAuth0User';
import { ModalParams } from 'hooks/useModal';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

export interface CompanyAdminsProps extends ModalParams {}
interface CompanyAdmin {
  email: string;
  email_verified: boolean;
  name: string;
  status: string;
}

export const CompanyAdminsDialog = ({ isOpen, close }: CompanyAdminsProps) => {
  const { data = [], isLoading } = useQuery(
    ['company-admins'],
    () => getCompanyAdmins(),
    {
      refetchOnMount: false,
      staleTime: Infinity,
    },
  );
  const { companyName, macnum } = useAuth0User();

  const sortedData = useMemo(
    () =>
      data.sort((a: CompanyAdmin, b: CompanyAdmin) =>
        a.email.localeCompare(b.email),
      ),
    [data],
  );

  return (
    <Modal isVisible={isOpen} close={close}>
      <div className="flex h-auto w-[836px] flex-col items-start justify-start rounded bg-background-base-surface-2">
        <div className="flex w-full flex-col justify-center gap-2 rounded-t bg-background-base-surface-3 px-8 py-6">
          <p className="text-2xl font-bold text-content-base-default">
            Company details
          </p>
        </div>
        <div className="flex w-full flex-col items-start justify-start gap-4 p-6 pb-0">
          <div className="mb-2 flex w-full justify-between gap-4 ">
            <GraniteInput
              label="Company name"
              value={companyName}
              disabled
              className="w-full"
            />
            <GraniteInput
              label="Highest level Granite account #"
              value={macnum}
              disabled
              className="w-full"
            />
          </div>
          <label className="font-bold text-content-base-subdued">
            Company admins
          </label>
          <div className="vertical-scrollbar mb-8 flex h-[376px] max-h-[380px] min-h-0 w-full rounded bg-background-base-surface-1 p-4">
            {isLoading ? (
              <div className="flex w-full justify-center">
                <Loader animationClassname="!w-6 !h-6 !border-background_base_surface-3_subdued !border-t-button-background-primary-default self-center" />
              </div>
            ) : null}
            <ul className="ml-[10px] flex list-inside list-disc flex-col items-start justify-start gap-3">
              {sortedData?.length > 0
                ? sortedData.map((item: CompanyAdmin, key: number) => (
                    <li key={key}>
                      <span className="font-semibold text-content-accent-default">
                        {item.email}
                      </span>
                    </li>
                  ))
                : null}
            </ul>
          </div>
        </div>
        <div className="w-full p-8 pt-0">
          <GraniteButton
            variant="secondary"
            size="large"
            onClick={close}
            className="mt-3"
          >
            Close
          </GraniteButton>
        </div>
      </div>
    </Modal>
  );
};
