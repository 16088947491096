import React, { forwardRef } from 'react';
import clsx from 'clsx';

export interface CheckboxProps {
  label: string | React.ReactNode;
  checked?: boolean;
  isPartiallChecked?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  disabled?: boolean;
  title?: string;
}

export const Checked = ({ disabled }: { disabled?: boolean }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 25 24"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx(
        disabled
          ? 'fill-input-background-disabled stroke-input-stroke-filled'
          : 'fill-button-background-primary-default stroke-input-content-selected',
      )}
    >
      <rect x="2.5" y="2" width="20" height="20" rx="4" stroke="none" />
      <path
        d="M8.5 12L11.5 15L16.5 9"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const Indeterminate = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="1 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="2.5" y="2" width="20" height="20" rx="4" fill="#82F0FF" />
      <path
        d="M8.5 12H16.5"
        stroke="#191925"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    { label, checked, isPartiallChecked, className, disabled, title, ...props },
    ref,
  ) => {
    return (
      <label
        className={clsx(
          'flex items-center text-base font-light',
          disabled
            ? 'cursor-default select-none text-button-content-primary-disabled'
            : 'cursor-pointer text-white',
          className,
        )}
      >
        {title && (
          <p className="w-full text-left text-base font-bold text-content-base-subdued">
            {title}
          </p>
        )}
        <div className="flex items-center text-base font-light">
          <input
            {...props}
            ref={ref}
            type="checkbox"
            checked={checked}
            className="absolute h-[20px] w-[20px] cursor-pointer opacity-0"
            disabled={disabled}
          />
          <div
            className={clsx(
              `h-[20px] w-[20px] rounded border border-solid bg-background-base-surface-1`,
              (checked || isPartiallChecked) && 'bg-input-background-selected',
              disabled
                ? 'border-input-stroke-disabled !bg-input-background-disabled'
                : 'border-button-background-destructive-disabled',
            )}
          >
            {checked && !isPartiallChecked && <Checked disabled={disabled} />}
            {!checked && isPartiallChecked && <Indeterminate />}
          </div>
          {label && <span className={clsx('ml-2')}>{label}</span>}
        </div>
      </label>
    );
  },
);

Checkbox.displayName = 'Checkbox';

export default Checkbox;
