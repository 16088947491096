import { useCallback, useState } from 'react';
import { Add } from 'react-ionicons';

import { GraniteButton } from 'components/V2/Button/GraniteButton';

import { LocationAccordion } from './LocationAccordion';
import { LocationField } from './LocationField';
import { QuoteLocation } from 'api/accessexpress/schema';
import './locations.css';

const MAX_LOCATIONS_COUNT = 20;

export type LocationValue = QuoteLocation | undefined;

interface LocationsProps {
  locations: LocationValue[];
  onChange: (locations: LocationValue[]) => void;
}

export const Locations = ({ locations, onChange }: LocationsProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuStateChange = (isOpen: boolean) => {
    setTimeout(() => {
      setIsMenuOpen(isOpen);
    }, 200);
  };

  const handleChange = useCallback(
    (index: number, value: LocationValue) => {
      if (value === undefined) {
        onChange(locations.filter((_, i) => i !== index));
      } else {
        onChange(
          locations.map((location, i) => (i === index ? value : location)),
        );
      }
    },
    [locations, onChange],
  );

  const [isOpen, setIsOpen] = useState(true);

  const handleAddLocation = useCallback(() => {
    onChange([...locations, undefined]);
    setIsOpen(true);
  }, [locations, onChange]);

  return (
    <>
      <LocationAccordion
        isOpen={isOpen}
        isMenuOpen={isMenuOpen}
        title={
          <span className="font-bold">
            Locations{' '}
            <span className="text-content-base-subdued">
              ({locations.length})
            </span>
          </span>
        }
        toggle={() => setIsOpen(!isOpen)}
      >
        <div className="location-field-wrapper -mt-2 grid gap-y-4">
          {locations.map((location, index) => (
            <div
              key={index}
              className="grid grid-cols-[auto,1fr] items-baseline gap-x-4 gap-y-4"
            >
              <div className="w-5 pl-2 text-right text-content-base-subdued">
                {index + 1}.
              </div>
              <LocationField
                index={index}
                onChange={handleChange}
                value={location}
                onRemove={() => handleChange(index, undefined)}
                onMenuStateChange={handleMenuStateChange}
              />
            </div>
          ))}
        </div>
      </LocationAccordion>
      <GraniteButton
        variant="secondary"
        className="!box-border !max-w-[180px]"
        onClick={handleAddLocation}
        disabled={locations.length >= MAX_LOCATIONS_COUNT}
      >
        Add location
        <Add width="20px" height="20px" color="inherit" />
      </GraniteButton>
    </>
  );
};
