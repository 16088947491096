import { useQuery } from 'react-query';
import { getEmbeddedDashboard } from '../../api/nocexpress/api';
import { useAuth0User } from '../../hooks/useAuth0User';
import { ComponentProps, useState } from 'react';
import Skeleton from 'components/Skeleton/Skeleton';

export type DashboardIframeProps = ComponentProps<'iframe'>;

const Loading = () => (
  <div className="mt-12 flex flex-col gap-4">
    <div className="flex flex-col gap-4 lg:flex-row">
      <div className="flex flex-1 flex-col gap-4">
        <div className="flex flex-col gap-4 lg:flex-row">
          <Skeleton className="h-[162px] w-full" />
          <Skeleton className="h-[162px] w-full" />
        </div>
        <Skeleton className="h-[286px] w-full " />
      </div>
      <Skeleton className="h-[464px] w-full lg:w-[534px] " />
    </div>
    <div className="flex flex-col gap-4 lg:flex-row">
      <div className="flex w-full flex-col gap-4">
        <div className="flex flex-col gap-4 lg:flex-row">
          <Skeleton className="h-[94px] w-full" />
          <Skeleton className="h-[94px] w-full" />
          <Skeleton className="h-[94px] w-full" />
        </div>
        <Skeleton className="h-[204px] w-full" />
      </div>
      <Skeleton className="h-[313px] w-full lg:w-[534px] " />
    </div>
    <div className="flex flex-col gap-4 lg:flex-row">
      <Skeleton className="h-[310px] w-full " />
      <Skeleton className="h-[310px] w-full lg:w-[539px] " />
    </div>
    <Skeleton className="h-[410px] w-full " />
  </div>
);

export const DashboardIframe = (props: DashboardIframeProps) => {
  const { sub } = useAuth0User();
  const [isIframeLoading, setIsIframeLoading] = useState(true);
  const { data, isLoading } = useQuery(
    ['noc', 'dashboard', sub],
    getEmbeddedDashboard,
    {
      refetchOnMount: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
    },
  );

  return (
    <>
      {(isLoading || isIframeLoading) && <Loading />}
      <iframe
        {...props}
        srcDoc={data}
        style={{ display: !isIframeLoading ? 'block' : 'none' }}
        onLoad={() => setIsIframeLoading(false)}
      />
    </>
  );
};
