import { apiClient } from 'api/apiClient';
import {
  GetCompaniesParams,
  GetCompaniesParamsSchema,
  GetCompaniesResponse,
} from './schemas';

export const getCompanies = async (
  user_id: string,
  params?: GetCompaniesParams,
): Promise<GetCompaniesResponse> => {
  const parameters = GetCompaniesParamsSchema.parse(params ?? {});

  const queryParams = {
    ...parameters,
  };
  const response = await apiClient.get(`/api/v1/users/${user_id}/companies`, {
    params: queryParams,
  });

  return response.data;
};

export const getCompanyAdmins = async () => {
  const response = await apiClient.get(`/api/v1/users/admin-contacts`);

  return response.data;
};

export const getSites = async () => {
  const response = await apiClient.get('/api/v1/companies/sites/download/');
  return response.data;
};
