import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import uniq from 'lodash/uniq';

import { getQuoteFormalPricing, submitQuoteOrder } from 'api/accessexpress/api';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { WithLoader } from 'components/WithLoader/WithLoader';
import { formatCurrency } from 'shared/util/formatCurrency';
import { ReviewOrderConfirmationDialog } from 'screens/OpenQuote/BaseComponents/ReviewOrderConfirmationDialog';
import { useModal } from 'hooks/useModal';
import BasicPagination, {
  useBasicPagination,
} from 'components/BasicPagination';

import { Service } from './Service';
import { groupProducts } from './utils';
import { ProductType } from './schemas';
import { useOpenQuoteContext } from 'screens/OpenQuote/Wizard/OpenQuoteWizardReducer';
import DiscardProgressModal from 'screens/OpenQuote/DiscardProgressModal/DiscardProgressModal';
import Loader from 'components/Loader';
import clsx from 'clsx';

const SelectServices = () => {
  const { quoteId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const { data, isLoading } = useQuery(
    ['access-express-quote-formal-pricing', quoteId],
    () => getQuoteFormalPricing(quoteId ?? ''),
    {
      enabled: !!quoteId,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    },
  );

  const groupedProducts = useMemo(
    () =>
      (groupProducts(data?.locations || []) || []).sort(
        (a, b) => b.offerings.length - a.offerings.length,
      ),
    [data?.locations],
  );

  const { from, to, ...paginationProps } = useBasicPagination(
    groupedProducts.length,
  );

  const [productMap, setProductMap] = useState<
    Record<string, { product: ProductType; mns: boolean }>
  >({});
  const [selectedMap, setSelectedMap] = useState<Record<string, boolean>>({});

  const handleProductChange = useCallback(
    (id: string, type: string, product: ProductType, mns: boolean) => {
      setProductMap((prev) => ({
        ...prev,
        [[id, type].join(',')]: { product, mns },
      }));
    },
    [],
  );

  const handleSelectChange = useCallback(
    (id: string, type: string, checked: boolean) => {
      if (checked) {
        setSelectedMap((prev) => ({
          ...prev,
          [[id, type].join(',')]: checked,
        }));
      } else {
        setSelectedMap((prev) => {
          const { [[id, type].join(',')]: _, ...rest } = prev;
          return rest;
        });
      }
    },
    [],
  );

  const anySelected = useMemo(
    () => Object.keys(selectedMap).length > 0,
    [selectedMap],
  );

  const totalMRC = useMemo(() => {
    return Object.entries(productMap)
      .filter(([key]) => Object.keys(selectedMap).includes(key))
      .reduce((acc, [_, product]) => {
        return (
          acc +
          (product?.product?.formal_broadband_pricing?.total_mrc ?? 0) +
          (product?.product?.formal_dia_pricing?.total_mrc ?? 0)
          // + (product?.mns
          //   ? product?.product?.type === 'broadband'
          //     ? 19.99
          //     : 39.99
          //   : 0)
        );
      }, 0);
  }, [productMap, selectedMap]);

  const { ...discardModalProps } = useModal();
  const { isOpen, open, close } = useModal();

  const handleSubmit = useCallback(() => {
    if (loading) return;
    open();
  }, [loading, open]);

  const handleConfirmation = useCallback(async () => {
    setLoading(true);
    const result = Object.entries(productMap).filter(([key]) =>
      Object.keys(selectedMap).includes(key),
    );
    await submitQuoteOrder(quoteId!, {
      ordered_products: result
        .map((r) => r[1].product.id)
        .map((r) => ({ id: r })),
    });
    setLoading(false);
    navigate(`/access-express/index`);
  }, [navigate, productMap, quoteId, selectedMap]);

  const handleClose = useCallback(() => close(), [close]);

  const formData = useMemo(() => {
    const productIds = Object.keys(selectedMap).map((id) => id.split(','));
    const productLocationIds = uniq(productIds.map((id) => id[0]));
    const productTypes = uniq(productIds.map((id) => id[1])).map((type) => {
      if (type === 'broadband') {
        return 'Broadband';
      }
      if (type === 'dia') {
        return 'DIA';
      }
      return '';
    });

    return {
      id: data?.qe_quote_request_id ? data?.qe_quote_request_id.toString() : '',
      name: data?.quote_name || '',
      creation_date: data?.qe_quote_created_at,
      expiration_date: data?.formal_quote_expires_at,
      service_categories: productTypes.join(', '),
      total_services: productIds.length.toString(),
      total_locations: productLocationIds.length.toString(),
      total_nonrecurring_charges: 0,
      total_monthly_charges: totalMRC,
    };
  }, [
    data?.formal_quote_expires_at,
    data?.qe_quote_created_at,
    data?.qe_quote_request_id,
    data?.quote_name,
    selectedMap,
    totalMRC,
  ]);

  const { wizardStore } = useOpenQuoteContext();

  const quoteTitle = useMemo(() => {
    return (
      <WithLoader isLoading={isLoading}>
        <div className="flex items-center justify-center gap-6">
          <h1 className="text-4xl font-bold text-content-base-default">
            {data?.quote_name}
          </h1>
          <div className="rounded-lg bg-blue-600 px-4 py-[6px]">
            <span className="h-auto text-center text-base font-bold text-content-base-default">
              QR #{data?.qe_quote_request_id}
            </span>
          </div>
        </div>
      </WithLoader>
    );
  }, [data?.qe_quote_request_id, data?.quote_name, isLoading]);

  useEffect(() => {
    wizardStore.setStep(5, quoteTitle);
  }, [quoteTitle, wizardStore]);

  return (
    <>
      <WithLoader isLoading={isLoading}>
        <div className="select-services mb-6 flex w-full flex-col items-start gap-8 rounded bg-background-base-surface-2 p-6 shadow">
          <div className="flex flex-col">
            <h2 className="mb-2 text-2xl font-bold text-content-base-default">
              Select services to order for your locations
            </h2>
            <div className="text-base font-bold text-content-base-subdued">
              Review the options available at each location and choose your
              preferred plan by customizing the service and selecting the
              checkbox.
            </div>
          </div>
          <div className="grid w-full grid-cols-1 gap-5 xl:grid-cols-2">
            {groupedProducts.slice(from, to).map((location) => (
              <Service
                key={[location.id, location.type].join(',')}
                data={location}
                selected={
                  selectedMap[[location.id, location.type].join(',')] || false
                }
                onProductChange={handleProductChange}
                onSelectChange={handleSelectChange}
              />
            ))}
          </div>
          <BasicPagination {...paginationProps} />

          <div className="flex w-full grow flex-row gap-10 rounded bg-background-base-surface-0 px-6 py-4 font-bold">
            <div className="flex flex-1">Total charges</div>
            <div className="flex flex-none flex-col items-end">
              <div>Non-recurring charges</div>
              <div className="text-3xl text-content-accent-default">$0.00</div>
            </div>
            <div className="flex flex-none flex-col items-end">
              <div>Monthly recurring charges</div>
              <div className="text-3xl text-content-accent-default">
                {formatCurrency(totalMRC)}
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-row gap-4">
          <GraniteButton
            variant="secondary"
            size="large"
            onClick={discardModalProps.open}
          >
            Cancel
          </GraniteButton>
          <GraniteButton
            variant="primary"
            size="large"
            disabled={!anySelected}
            onClick={handleSubmit}
            className={clsx(loading && 'loading-button')}
          >
            Submit order
            {loading && <Loader animationClassname="!w-3 !h-3" />}
          </GraniteButton>
        </div>
      </WithLoader>
      <DiscardProgressModal {...discardModalProps} />
      <ReviewOrderConfirmationDialog
        onConfirmation={handleConfirmation}
        isOpen={isOpen}
        close={handleClose}
        formData={formData}
      />
    </>
  );
};

export default SelectServices;
