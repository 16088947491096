import { useMutation } from 'react-query';
import { Modal } from 'components/Modal/Modal';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { ModalParams } from 'hooks/useModal';
import { SelectedTierInfo } from '../SelectServiceBundle/SelectServiceBundle';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  RequestQoute,
  RequestQuoteSchema,
} from '../SelectServiceBundle/schema';
import { GraniteInput } from 'components/V2/Input/GraniteInput';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { requestQuote } from '../SelectServiceBundle/utils';
import AllSetDialog from './AllSetDialog';
import clsx from 'clsx';
import Loader from 'components/Loader';

export interface RequestQuoteModalProps extends ModalParams {
  selectedTiers: { [key: string]: SelectedTierInfo };
  quoteName: string | null;
  quoteId: string;
}

export const RequestQuoteModal = ({
  isOpen,
  close,
  selectedTiers,
  quoteName,
  quoteId,
}: RequestQuoteModalProps) => {
  const [step, setStep] = useState(1);
  const navigate = useNavigate();

  const {
    handleSubmit,
    register,
    reset,
    setValue,
    formState: { errors },
  } = useForm<RequestQoute>({
    resolver: zodResolver(RequestQuoteSchema),
    defaultValues: {
      name: quoteName || '',
    },
  });

  useEffect(() => {
    if (quoteName) {
      setValue('name', quoteName);
    }
  }, [quoteName, setValue]);

  const quoteAlreadyHasName = !!quoteName;

  const requestQuoteModalMutation = useMutation(
    ({ name }: { name: string }) => {
      console.log(selectedTiers);
      return requestQuote({
        id: quoteId,
        name,
      });
    },
    {
      onSuccess: (data: string) => {
        if (data === 'Quote has been requested') {
          setStep(2);
        }
      },
    },
  );

  const onSubmit = (request: RequestQoute) => {
    if (requestQuoteModalMutation.isLoading) return;
    requestQuoteModalMutation.mutate(request);
  };

  const onError = (err: unknown) => console.log(err);

  const closeModal = () => {
    close();
    if (!quoteName) reset({});
  };

  const onCloseClick = () => {
    closeModal();
    navigate('/access-express/index');
  };

  return (
    <Modal isVisible={isOpen} close={close} className="w-[624px]">
      {step === 1 && (
        <div className="flex flex-col gap-2 rounded-lg bg-background-base-surface-2">
          <div
            className={clsx(
              'flex flex-col items-start justify-start rounded-t-lg  px-8 py-6 pb-0 font-bold',
              !quoteAlreadyHasName && 'bg-background-base-surface-3 !py-6',
            )}
          >
            <h1 className="text-[28px] text-content-base-default">
              Ready to see pricing?
            </h1>{' '}
            <p className="font-semibold text-content-base-subdued">
              Once requested, you will not be able to update locations or adjust
              basic service requirements.
            </p>
          </div>
          <div className={clsx(quoteAlreadyHasName && 'pt-0', 'p-8')}>
            <form
              onSubmit={handleSubmit(onSubmit, onError)}
              className="grid grid-cols-1 gap-6"
            >
              {!quoteAlreadyHasName && (
                <GraniteInput
                  label="Give this quote request a name so that you can reference it later"
                  className="col-span-1"
                  error={errors.name?.message}
                  {...register(`name`)}
                />
              )}
              <div
                className={clsx(quoteAlreadyHasName && 'mt-6', 'flex gap-5')}
              >
                <GraniteButton
                  size="large"
                  variant="secondary"
                  onClick={closeModal}
                >
                  Cancel
                </GraniteButton>
                <GraniteButton
                  size="large"
                  variant="primary"
                  type="submit"
                  className={clsx(
                    requestQuoteModalMutation.isLoading && 'loading-button',
                  )}
                >
                  Request quote
                  {requestQuoteModalMutation.isLoading && (
                    <Loader animationClassname="!w-3 !h-3" />
                  )}
                </GraniteButton>
              </div>
            </form>
          </div>
        </div>
      )}
      {step === 2 && <AllSetDialog onClose={onCloseClick} />}
    </Modal>
  );
};
