import { z } from 'zod';
import { UserRoles } from '../../api/users/schemas/UserRole';
import {
  LETTERS_SPACES_HYPENS_APOSTROPHE_REGEXP,
  VALID_PHONE_NUMBER,
} from 'shared/constants/validation-regex-constants';
import {
  LETTERS_SPACES_HYPENS_ERROR_LABEL,
  VALID_PHONE_ERROR_LABEL,
} from '../../shared/constants/error-labels';
import {
  UpdateUserProfileRequest,
  UserProfileResponse,
} from '../../api/users/schemas/UserProfile';
import { InviteUserRequest } from '../../api/users/schemas/Invitations';

export const EditUserFormSchema = z.object({
  role: z.object({ value: UserRoles, label: z.string() }),
  name: z.string(),
  phone_number: z
    .string()
    .nonempty(VALID_PHONE_ERROR_LABEL)
    .length(10, VALID_PHONE_ERROR_LABEL)
    .regex(VALID_PHONE_NUMBER, VALID_PHONE_ERROR_LABEL),
});

export type EditUserForm = z.infer<typeof EditUserFormSchema>;

export const InviteUserFormSchema = z.object({
  name: z
    .string()
    .nonempty('Required')
    .max(100, 'Only 100 characters allowed')
    .regex(LETTERS_SPACES_HYPENS_APOSTROPHE_REGEXP, {
      message: LETTERS_SPACES_HYPENS_ERROR_LABEL,
    }),
  email: z.string().nonempty('Required').email(),
  phone: z
    .string()
    .nonempty(VALID_PHONE_ERROR_LABEL)
    .length(10, VALID_PHONE_ERROR_LABEL)
    .regex(VALID_PHONE_NUMBER, VALID_PHONE_ERROR_LABEL),
  companyId: z.string().optional(),
  role: z.object({ value: UserRoles, label: z.string() }),
});

export type InviteUserForm = z.infer<typeof InviteUserFormSchema>;

export const inviteUserFormToRequest = (
  formData: InviteUserForm,
  inviterName: string | undefined,
): InviteUserRequest => {
  return [
    {
      name: formData.name,
      phone_number: formData.phone,
      email: formData.email,
      role: formData.role.value,
      inviter_name: inviterName,
    },
  ];
};

export const responseToUserEditForm = (
  response?: UserProfileResponse,
): EditUserForm | undefined => {
  if (!response) return undefined;
  return {
    role: { value: response.role, label: response.role },
    name: response.name,
    phone_number: response.phone_number,
  };
};

export const userEditFormToEditRequest = (
  form: EditUserForm,
  base: UserProfileResponse,
): UpdateUserProfileRequest => {
  return {
    name: form.name,
    phone_number: form.phone_number,
    role: form.role.value,
    company_title: base.company_title,
    department: base.department,
    secondary_email: base.secondary_email,
    secondary_phone_number: base.secondary_phone_number,
  };
};
