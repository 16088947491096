import { ComponentProps, ForwardedRef, forwardRef } from 'react';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import clsx from 'clsx';
import Checkbox from 'components/Checkbox';

interface MultiCheckboxOptionsProps<T>
  extends Omit<ComponentProps<typeof GraniteButton>, 'onClick' | 'value'> {
  label: string;
  subText?: string;
  value: T[];
  option: T;
  onClick: (v: T[]) => void;
}

const MultiCheckboxOptions = <T,>(
  {
    label,
    subText,
    onClick,
    option,
    value,
    className,
    children,
    ...props
  }: MultiCheckboxOptionsProps<T>,
  ref: ForwardedRef<HTMLButtonElement>,
) => {
  const isSelected = value.includes(option);

  const handleClick = () => {
    let newValue;
    if (isSelected) {
      newValue = value.filter((v) => v !== option);
    } else {
      newValue = [...value, option];
    }
    onClick(newValue);
  };

  return (
    <button
      className={clsx(
        'tile-select flex flex-col !items-stretch gap-1 !text-left !font-semibold',
        isSelected && 'selected',
        className,
      )}
      type="button"
      onClick={handleClick}
      ref={ref}
      aria-label={label}
      {...props}
    >
      <div className="flex justify-between gap-2">
        <span>{label}</span>
        <div>
          <Checkbox label="" checked={isSelected} className="!mr-0" />
        </div>
      </div>
      {subText && (
        <span
          className={clsx(
            'text-xs',
            !isSelected && 'text-content-base-subdued',
          )}
        >
          {subText}
        </span>
      )}
      {children}
    </button>
  );
};

MultiCheckboxOptions.displayName = 'MultiCheckboxOptions';

export default forwardRef(MultiCheckboxOptions) as <T>(
  props: MultiCheckboxOptionsProps<T> & {
    ref?: React.ForwardedRef<HTMLButtonElement>;
  },
) => ReturnType<typeof MultiCheckboxOptions>;
